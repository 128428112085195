import withName from "../../hoc/withName";
import { MultiSelect } from "primereact/multiselect";

const MultiSelectPrime = ({ value = null,filter=true, options=null, setValue=null,onChange=null, optionValue=null,small=null,showClear=true,smallChips=null,className=null, ...multiselectProps }) => {

    return (
        <>
            <MultiSelect
                value={options?.find(o => o?.[optionValue] === value)??value}
                className={`multiselect-prime${small?" small":""}${smallChips?" smallChips":""} ${className?className:""}`}
                filter={filter} showClear={showClear} panelClassName="multiselect-panel-prime"
                onChange={onChange?onChange:((e) => setValue?.(e.value))} options={options} {...multiselectProps}
            />
        </>
    );
};

export default withName(MultiSelectPrime, "MultiSelectPrime");
