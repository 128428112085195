import React, { Fragment, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { RestAPI } from "@/utils/api-rest";
import { fetchAuthSession } from "aws-amplify/auth";
import { Field, useField } from "formik";
import countriesList from "i18n-iso-countries";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle, faEye, faEyeSlash, faTrash, faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import { RadioButton } from "primereact/radiobutton";

import { applyFormulas } from "../../utils/area";
import GooglePlacesAutocomplete from "../GooglePlacesAutocomplete/GooglePlacesAutocomplete";
/* const rutMask = createRutMask(); */
import { useNavigate } from "react-router";
import libphonenumberMetadata from "libphonenumber-js/metadata.min";
import { validateEmail50, validateSomething } from "./Validations";

import TagManager from "react-gtm-module";

import en from "i18n-iso-countries/langs/en.json";
import es from "i18n-iso-countries/langs/es.json";
import tr from "i18n-iso-countries/langs/tr.json";
import it from "i18n-iso-countries/langs/it.json";
import fr from "i18n-iso-countries/langs/fr.json";
import ButtonPrime from "../PrimeComponents/ButtonPrime";
import TooltipPrime from "../PrimeComponents/TooltipPrime";
import CalendarPrime from "../PrimeComponents/CalendarPrime";
import MultiSelectPrime from "../PrimeComponents/MultiSelectPrime";
import DropdownPrime from "../PrimeComponents/DropdownPrime";
import ChipPrime from "../PrimeComponents/ChipPrime";
import InputTextPrime from "../PrimeComponents/InputTextPrime";
import { Checkbox } from "primereact/checkbox";
// TODO: Move to l10n loader
countriesList.registerLocale(en);
countriesList.registerLocale(es);
countriesList.registerLocale(tr);
countriesList.registerLocale(it);
countriesList.registerLocale(fr);

const DateToStringTime = (date) => {
    if (!date || !date.valueOf()) return null;
    const hh = String(date.getHours()).padStart(2, "0");
    const mm = String(date.getMinutes()).padStart(2, "0");
    const ss = String(date.getSeconds()).padStart(2, "0");
    return `${hh}:${mm}:${ss}`;
};

const isTheSame = (a, b) => {
    if (Array.isArray(a)) {
        return Array.isArray(b) && (a.length === b.length) && a.every((item, index) => item === b[index]);
    }
    return a === b;
};

const AREA_FORMAT_KEY = "number.dec.4";

const GA_EDIT_TYPE = {
    actuador: "actuador",
    node: "nodo",
    pozo: "pozo",
    general: "campo_info",
    equipo: "equipo",
    sensor: "sensor",
    permission: "usuario",
    sector: "sector",
    integrador_permiso: "integrador_permiso",
    inyector: "inyector",
    accounts: "cuenta",
    personal: "personal",
    sdb_converter: "sdb_converter"
};

const sendGA = (endpoint, data) => {
    const arrSplit = endpoint.split("/");
    const campoId = parseInt(arrSplit[2]);
    const edit_type = arrSplit[3];
    let ga_edit_type = null;
    if (arrSplit[1] === "accounts") {
        ga_edit_type = GA_EDIT_TYPE["accounts"];
        TagManager.dataLayer({
            dataLayer: {
                event: "edicion",
                edit_type: ga_edit_type,
                cuenta_id: campoId,
                campo_id: null,
                user_email: null,
                page_path: window.location.pathname,
                page_search: window.location.pathname + window.location.search
            }
        });
    } else if (arrSplit[1] === "personal") {
        ga_edit_type = GA_EDIT_TYPE["personal"];
        TagManager.dataLayer({
            dataLayer: {
                event: "edicion",
                edit_type: ga_edit_type,
                user_email: data.email,
                page_path: window.location.pathname,
                page_search: window.location.pathname + window.location.search,
                campo_id: null,
                cuenta_id: null,
            }
        });
    } else {
        if (edit_type === "integrador" && arrSplit[5] === "updatePermiso") {
            ga_edit_type = GA_EDIT_TYPE["integrador_permiso"];
        } else if (edit_type === "sdb" && arrSplit[4] === "update") {
            ga_edit_type = GA_EDIT_TYPE["sdb_converter"];
        } else {
            ga_edit_type = GA_EDIT_TYPE[edit_type];
        }
        TagManager.dataLayer({
            dataLayer: {
                event: "edicion",
                edit_type: ga_edit_type,
                campo_id: campoId,
                page_path: window.location.pathname,
                page_search: window.location.pathname + window.location.search,
                cuenta_id: null,
                user_email: null,
            }
        });
    }
};

const callSend = (endpoint, oldData, values, t, setInfo, passToken, areaInfo, promiseWrapper, mapRes, farmId, closeEstadosPromise, actions, isZoneEdit, postActionNotModify) => {
    let oldValue = null, newValue = null, isModified = false;
    const keys = Object.keys(values);
    const newValues = keys.reduce((result, k) => {
        oldValue = Object.getOwnPropertyDescriptor(oldData, k);
        newValue = Object.getOwnPropertyDescriptor(values, k);
        if (newValue.value instanceof Date) {
            newValue.value = DateToStringTime(newValue.value);
        }
        else if (k === "customizations") {
            // Handle customizations as nested objects
            const customizations = newValue.value;
            if (customizations && typeof customizations === "object") {
                for (const [innerKeyCstm, valueCstm] of Object.entries(customizations)) {
                    if (valueCstm && typeof valueCstm === "object") {
                        for (const [innerKeyValue, valueVal] of Object.entries(valueCstm)) {
                            if(valueVal !== undefined && (!oldValue?.value?.[innerKeyCstm]?.[innerKeyValue] || oldValue?.value?.[innerKeyCstm]?.[innerKeyValue] !== newValue?.value?.[innerKeyCstm]?.[innerKeyValue])){
                                result[k] = newValue.value === "" ? null : newValue.value;
                                isModified = true;
                            }
                        }
                    }
                }
            }
        }
        else if (newValue.value !== undefined && (!oldValue || !isTheSame(oldValue.value, newValue.value)) && k !== "superficie_dividida") {
            // needed because of https://github.com/formium/formik/issues/1869
            result[k] = newValue.value === "" ? null : newValue.value;
            isModified = true;
        }
        else if (k === "tipo") {
            result.tipo = newValue.value;
        }
        return result;
    }, {});
    if (values.email) {
        newValues.email = values.email;
    }
    // we can edit area for irrigation sectory only if has one block
    if (newValues.tipo && newValues.superficie !== undefined && newValues.tipo.includes(3) && !oldData.one_block) delete newValues.superficie;

    async function sendData(body) {
        try {
            if (passToken) {
                body.token = (await fetchAuthSession({ forceRefresh: true })).tokens.accessToken.toString();
            }

            const { formula, formulaTo, formulaInv, formulaInvTo } = areaInfo || {};
            // convert from kilo_unidad to unidad
            if (body && body.superficie && formulaInv && formulaInvTo) {
                // we can use formula2( formula1( sup * 10.000 ) )
                // instead of formula2( formula1( sup ) * 10.000 ) for area
                body.superficie = applyFormulas(body.superficie * 10000, formulaInvTo, formulaInv);
            }

            if (((body.superficie !== undefined) || (body.caudal_teorico !== undefined)) && promiseWrapper) {
                body.recalc = await promiseWrapper.getPromiseAnswer();
            }

            // cultivo has changed in zone, so need to check open phenological stages and close it
            if (isZoneEdit && body.plantilla_estado_fenologico_id !== undefined){
                if (oldData.plantilla_estado_fenologico_id){
                    const closeEstados = await closeEstadosPromise.getPromiseAnswer();
                    if (!closeEstados) {
                        actions?.resetForm();
                        return;
                    }
                }
            }
            const data = await RestAPI.post("apiAdminDropControl", endpoint, { body });
            console.log(data);
            if (areaInfo && data.superficie !== undefined) areaInfo.apiArea = data.superficie;

            // convert from unidad to kilo_unidad
            if (data && data.superficie && formula && formulaTo) {
                // we can use formula2( formula1( sup / 10.000 ) )
                // instead of formula2( formula1( sup ) / 10.000 ) for area
                data.superficie = applyFormulas(data.superficie / 10000, formulaTo, formula, AREA_FORMAT_KEY);
            }

            if (data && data.mapa_json) data.mapa_json = JSON.parse(data.mapa_json);
            Object.assign(oldData, data);

            setInfo({ isLoaded: true, data: oldData, reconnect:endpoint?.endsWith("reconnect-service") });
            const resolved = mapRes && (await mapRes);
            if (resolved !== undefined && !resolved) {
                toast.error(t("forms.toastError"), { autoClose: 6000 });
            } else {
                if(endpoint.endsWith("reconnect-service")){
                    toast.success(t("marketplace.reconnect_toast"), { autoClose: 6000 });
                }else{
                    toast.success(t("forms.toastSuccess"), { autoClose: 6000 });
                }

                if (data.ticketResult){
                    let ticketMessage;
                    if (data.ticketResult.ticketCreated || data.ticketResult.emailSent) ticketMessage = t("forms.ticketCreated");
                    if (data.ticketResult.ticketUpdated) ticketMessage = t("forms.ticketUpdated");
                    if (data.ticketResult.ticketId) ticketMessage += ` ${t("forms.ticketId", { id: data.ticketResult.ticketId })}`;
                    if (ticketMessage) toast.info(ticketMessage, { autoClose: false });
                }
                sendGA(endpoint, body);
            }
        } catch (err) {
            const resp = err?.response ?? {};
            let _errorBody = null;
            try {
                if (resp?.body && typeof resp?.body === "string") {
                    _errorBody = JSON.parse(resp?.body);
                }
            } catch (e) {
                const _errorBody = null;
            }
            if (resp.data?.deviceAlreadyExists || _errorBody?.deviceAlreadyExists) {
                toast.error(t("external.deviceAlreadyExists"), { autoClose: 5000 });
            }
            else if (resp.data?.alreadyConnected || _errorBody?.alreadyConnected || resp.data?.providerExists || _errorBody?.providerExists) {
                toast.error(t("external.alreadyConnected"), { autoClose: 5000 });
            }
            else if (resp.data?.org_connected || _errorBody?.org_connected) {
                toast.error(t("external.org_connected"), { autoClose: 5000 });
            }
            else if (resp.data?.plotAlreadyExists || _errorBody?.plotAlreadyExists) {
                toast.error(t("external.plotAlreadyExists"), { autoClose: 5000 });
            }
            else if (resp.status === 401 || _errorBody?.status === 401) {
                toast.error(t("forms.toastError401"), { autoClose: 6000 });
            }
            else if (resp.status === 409 || _errorBody?.status === 409) {
                toast.error(t("forms.toastError409"), { autoClose: 6000 });
            }
            else if (resp.status === 429 || _errorBody?.status === 429) {
                const secondsRegex = /expires in (\d+) seconds/;
                const secondsMatch = resp.data?.detail?.match(secondsRegex) || _errorBody?.detail?.match(secondsRegex);
                if (secondsMatch && secondsMatch.length > 1) {
                    const seconds = parseInt(secondsMatch[1], 10);
                    toast.warn(t("forms.toastError429mktp", { seconds }), { autoClose: 6000 });
                } else {
                    toast.error(t("forms.toastError"), { autoClose: 6000 });
                }
            }
            else {
                toast.error(t("forms.toastError"), { autoClose: 6000 });
            }
            if(postActionNotModify){
                postActionNotModify();
            }
        }
    }
    if (isModified) {
        sendData(newValues);
    } else if (mapRes !== undefined) {
        mapRes.then((resolved) => {
            if (resolved) {
                toast.success(t("forms.toastSuccess"), { autoClose: 6000 });
            } else toast.error(t("forms.toastError"), { autoClose: 6000 });
        });
    } else {
        toast.info(t("forms.toastInfo"), { autoClose: 6000 });
        if(postActionNotModify){
            postActionNotModify();
        }
    }
};

const LocationSubsection = ({ validate, edit, language, ubicacion, pais, error, t }) => {
    const [{ value: pValue }, , { setValue: setPais, setTouched }] = useField({ name: "pais", validate });
    const [{ value: uValue }, , { setValue: setUbicacion }] = useField({ name: "ubicacion" });
    const [, , { setValue: setPrefix }] = useField({ name: "prefix" });

    const baseLang = language.split("-")[0];
    const tUbicacion = edit ? uValue : ubicacion;
    const tPais = edit ? pValue : pais;
    const text = `${tUbicacion && Object.keys(tUbicacion).length > 0 ? `${tUbicacion.ciudad}, ${tUbicacion.region} - ` : ""}${tPais ? countriesList.getName(tPais, baseLang) : ""}`;
    if (edit) {
        return (
            <div className="row linea-formulario">
                <div className="col-12 col-md-6 text-md-right">
                    <label className='boldie'>{t("personal.location")} *</label>
                </div>
                <div className="col-12 col-md-6 text-md-left d-block">
                    <div className="col-md-10 p-0">
                        <GooglePlacesAutocomplete
                            apiKey="AIzaSyCvs7xPDh2vfmQO2w02lcItOXa6OKOVmQ4"
                            language={language}
                            placeholder={text || t("personal.location")}
                            onChange={(location) => {
                                setUbicacion({ ciudad: location.city, region: location.region });
                                const country = location.country.short_name;
                                setTouched(true);
                                setPais(country);
                                const pData = country && libphonenumberMetadata[country];
                                const prefix = pData && pData[0];
                                if (prefix) setPrefix("+" + prefix); // e.g US: +1, CL: +56
                            }}
                        />
                    </div>
                </div>
                {error ?
                    <div className="mb-2 col-12 d-block">
                        <div className="alert alert-warning m-0" role="alert">{t("personal.location")}: {error}</div>
                    </div>
                    : null}
            </div>
        );
    }

    if (!text) return null;
    return (
        <div className="row linea-formulario">
            <div className="col-12 col-md-6 text-md-right">
                <label className='boldie'>{t("personal.location")}</label>
            </div>
            <div className="col-12 col-md-6 text-md-left">
                <span>{text}</span>
            </div>
        </div>
    );
};

const HeaderButtons = ({ edit, canEdit, setEdit, setStatus, resetForm, form, mapRef }) => {
    const { t } = useTranslation();
    if (!canEdit) {
        if (edit) {
            resetForm && resetForm();
            setEdit ? setEdit(false) : setStatus({ edit: false });
        }
        return null;
    }

    const handleDeleteMapElement = () => {
        mapRef.deleteElement();
    };

    return (<div className='row'>
        {edit ? <>
            {/* Eliminar */}
            {mapRef &&
      <span id='deleteMapEl'>
          <FontAwesomeIcon
              className='btnEdit pointer'
              size='lg'
              style={{ color: "darkgray", marginRight: "5px" }}
              icon={faTrash}
              onClick={handleDeleteMapElement}
          />
      </span>}
            <ButtonPrime t={t} template="formSave" form={form} />
            <ButtonPrime t={t} template="formCancel" onClick={(event) => {
                event.preventDefault();
                resetForm && resetForm();
                setEdit ? setEdit(false) : setStatus({ edit: false });
            }}
            />
        </> :
            <FontAwesomeIcon
                icon={faPencilAlt}
                style={{ color: "grey", fontSize:"1.65rem", marginRight:"15px", cursor:"pointer" }}
                onClick={(event) => {
                    event.preventDefault();
                    setEdit ? setEdit(true) : setStatus({ edit: true });
                }}
            >{t("forms.edit")}
            </FontAwesomeIcon>}
    </div>);
};

const AreaRow = ({ edit, warning, dato1, dato2, nombre1, nombre2, campo1, unit1 }) => {
    if (!edit && !dato1 && !dato2) return null;

    return (
        <>
            {edit && campo1 ? (<>
                <div className="row linea-formulario">
                    <div className="col-12 col-md-6 text-md-right mtem-05">
                        <label className='boldie'>{nombre1}</label>
                    </div>
                    <div className="mb-2 col-12 col-md-6 d-block" lang={navigator.language}>
                        <div className="col-md-10 p-0 unit-input-content">
                            <Field type="number" step="0.0001" min="0" placeholder={0}
                                name={campo1} className="form-control text-center text-md-left"
                            />
                            {unit1 && <p className="unit-edit">{unit1}</p>}
                        </div>
                    </div>
                </div>
            </>) : !edit && dato1 ? (
                <div className="row linea-formulario">
                    <div className="col-12 col-md-6 text-md-right mtem-05">
                        <label className='boldie'>{nombre1}</label>
                    </div>
                    <div className="col-12 col-md-6 text-md-left mtem-05">
                        <span>{dato1}</span>
                        {warning && <><TooltipPrime id={`form-${nombre1}`}  content={warning} position="left">
                            <FontAwesomeIcon size="1x" icon={faInfoCircle} style={{ color: "lightblue" }} />
                        </TooltipPrime>
                        </>}
                    </div>
                </div>) : null}
            {dato2 ? (
                <div className="row linea-formulario">
                    <div className="col-12 col-md-6 text-md-right mtem-05">
                        <label className='boldie'>{nombre2}</label>
                    </div>
                    <div className="col-12 col-md-6 text-md-left mtem-05">
                        <span>{dato2}</span>
                    </div>
                </div>) : null}
        </>
    );
};

const FormRow = ({ edit, dato1, dato2, nombre1, nombre2, campo1, campo2, type1, type2, validate1, validate2,
    step1, step2, error1, error2, min1, min2, max1, max2, unit1, unit2, eyeForPassword }) => {
    const is_rut = (campo1 === "rut" && campo2 === "dv") ||
    (campo1 === "rut_representante_legal" && campo2 === "dv_representate_legal");
    const isTimezoneGTM = (campo1 == "huso_horarioGMT");
    const [changeInputType, setChangeInputType] = useState(false);
    const [errorsState, setErrorsState] = useState({});
    const { error1State, error2State } = errorsState;

    useEffect(() => {
        setTimeout(() => setErrorsState({ error1State: error1, error2State: error2 }), 80);
    }, [error1, error2]);


    if (edit) {
        return (
            <>
                {is_rut ?
                    <div className="row linea-formulario">
                        <div className="col-12 col-md-6 text-md-right mtem-05">
                            <label className='boldie'>{nombre1}</label>
                        </div>
                        <div className="mb-2 input-group col-md-6" lang={navigator.language}>
                            <div className="p-0">
                                <Field type="text" name={campo1} className="form-control text-center text-md-left"
                                    validate={validate1} placeholder={nombre1}
                                />
                            </div>

                            <div className="p-0">
                                <Field type="text" name={campo2} className="form-control text-center p-0 dv-rut"
                                    validate={validate2} placeholder={nombre2}
                                />
                            </div>
                        </div>
                        {(error1State || error2State) ?
                            <div className="mb-2 col-12 d-block">
                                <div className="alert alert-warning m-0" role="alert">{nombre1}: {error1State || error2State}</div>
                            </div>
                            : null}
                    </div>
                    :
                    <>
                        {campo1 ? (
                            <div className="row linea-formulario">
                                <div className="col-12 col-md-6 text-md-right">
                                    <label className='boldie'>{nombre1}</label>
                                </div>
                                <div className="mb-2 col-12 col-md-6 d-block" lang={navigator.language}>
                                    <div className="col-md-10 p-0 unit-input-content">
                                        <Field type={type1 ? type1 : "text"} name={campo1} className="form-control text-center text-md-left"
                                            validate={validate1} placeholder={nombre1} step={step1} min={min1} max={max1}
                                        />
                                        {unit1 && <p className="unit-edit">{unit1}</p>}
                                    </div>
                                </div>
                                {error1State ?
                                    <div className="mb-2 col-12 d-block">
                                        <div className="alert alert-warning m-0" role="alert">{nombre1}: {error1State}</div>
                                    </div>
                                    : null}
                            </div>
                        ) : null}

                        {campo2 ? (
                            <div className="row linea-formulario">
                                <div className="col-12 col-md-6 text-md-right">
                                    <label className='boldie'>{nombre2}</label>
                                </div>
                                <div className={`${campo2 !== "direccion" ? "mb-2 " : ""}col-12 col-md-6 d-block`} lang={navigator.language}>
                                    <div className="col-md-10 p-0 unit-input-content">
                                        {eyeForPassword ?
                                            <>
                                                {!changeInputType ?
                                                    <>
                                                        <Field id={campo2} type={type2 ? type2 : "text"} name={campo2} className="form-control text-center text-md-left"
                                                            validate={validate2} placeholder={nombre2} step={step2} min={min2} max={max2}
                                                        />
                                                        {unit2 && <p className="unit-edit">{unit2}</p>}
                                                        <span className="pointer" onClick={() => setChangeInputType(true)}><FontAwesomeIcon icon={faEye} color="gray" /></span>
                                                    </>
                                                    :
                                                    <>
                                                        <Field id={campo2} type="text" name={campo2} className="form-control text-center text-md-left"
                                                            validate={validate2} placeholder={nombre2} step={step2} min={min2} max={max2}
                                                        />
                                                        {unit2 && <p className="unit-edit">{unit2}</p>}
                                                        <span className="pointer" onClick={() => setChangeInputType(false)}><FontAwesomeIcon icon={faEyeSlash} color="gray" /></span>
                                                    </>}
                                            </>
                                            :
                                            <>
                                                <Field id={campo2} type={type2 ? type2 : "text"} name={campo2} className="form-control text-center text-md-left"
                                                    validate={validate2} placeholder={nombre2} step={step2} min={min2} max={max2}
                                                />
                                                {unit2 && <p className="unit-edit">{unit2}</p>}
                                            </>}

                                    </div>
                                </div>
                                {error2State ?
                                    <div className="mb-2 col-12 d-block">
                                        <div className="alert alert-warning m-0" role="alert">{nombre2}: {error2State}</div>
                                    </div>
                                    : null}
                            </div>
                        ) : null}
                    </>}
            </>
        );
    }

    if ((!dato1 || !campo1) && (!dato2 || !campo2)) return null;

    return (
        <>
            {is_rut ?
                <>
                    {dato1 ? (<div className="row linea-formulario">
                        <div className="col-12 col-md-6 text-md-right mtem-05">
                            <label className='boldie'>{nombre1}</label>
                        </div>
                        <div className="col-12 col-md-6 text-md-left mtem-05">
                            <span>{`${dato1}-${dato2 || ""}`}</span>
                        </div>
                    </div>) : null}
                </>
                :
                <>
                    {dato1 && campo1 ? (
                        <>
                            {
                                isTimezoneGTM ?
                                    <div className="row linea-formulario">
                                        <div className="col-12 col-md-6 text-md-right">
                                            <label className='boldie'>{nombre1}</label>
                                        </div>
                                        <div className="col-12 col-md-6 text-md-left">
                                            <span>{dato1.split(" ")[0]}</span>
                                            <span style={{ fontSize: "10px" }}> {dato1.split(" ")[1] + dato1.split(" ")[2]}</span>
                                        </div>
                                    </div>
                                    :
                                    campo1 === "administradores_campo" ?
                                        <div className="row linea-formulario">
                                            <div className="col-12 col-md-6 text-md-right">
                                                <label className='boldie'>{nombre1}</label>
                                            </div>
                                            <div className="col-12 col-md-6 text-md-left">
                                                <span>{
                                                    dato1.split(", ").map(((d, idx) =>
                                                        <Fragment key={idx}>{d}<br /></Fragment>
                                                    ))
                                                }
                                                </span>
                                            </div>
                                        </div>
                                        :
                                        <div className="row linea-formulario">
                                            <div className="col-12 col-md-6 text-md-right">
                                                <label className='boldie'>{nombre1}</label>
                                            </div>
                                            <div className="col-12 col-md-6 text-md-left">
                                                <span>{dato1}</span>
                                            </div>
                                        </div>
                            }
                        </>
                    ) : null}

                    {dato2 && campo2 ? (
                        <div className="row linea-formulario">
                            <div className="col-12 col-md-6 text-md-right">
                                <label className='boldie'>{nombre2}</label>
                            </div>
                            <div className="col-12 col-md-6 text-md-left">
                                <span>{dato2}</span>
                            </div>
                        </div>
                    ) : null}
                </>}
        </>
    );
};

const RawRow = ({ dato1, dato2, nombre1, nombre2, alert1, alert2 }) => {
    if ((!dato1) && (!dato2)) return null;
    return (<>
        {dato1 ? (
            <div className="row linea-formulario">
                <div className="col-12 col-md-6 text-md-right">
                    <label className='boldie'>{nombre1}</label>
                </div>
                <div className="col-12 col-md-6 text-md-left">
                    <span>{dato1} </span>
                    {alert1 && <TooltipPrime id={`form-${nombre1}`}  content={alert1} position="left">
                        <FontAwesomeIcon size="1x" icon={faInfoCircle} style={{ color: "lightblue" }} />
                    </TooltipPrime>}
                </div>
            </div>
        ) : null}

        {dato2 ? (
            <div className="row linea-formulario">
                <div className="col-12 col-md-6 text-md-right">
                    <label className='boldie'>{nombre2}</label>
                </div>
                <div className="col-12 col-md-6 text-md-left">
                    <span>{dato2} </span>
                    {alert2 && <TooltipPrime id={`form-${nombre2}`}  content={alert2} >
                        <FontAwesomeIcon size="1x" icon={faInfoCircle} style={{ color: "lightblue" }} />
                    </TooltipPrime>}
                </div>
            </div>
        ) : null}
    </>);
};

const PhoneRow = ({ edit, prefix, dato1, dato2, nombre1, nombre2, campo1, campo2, error1, error2 }) => {
    if (edit) {
        return (<>
            {campo1 ? (
                <div className="row linea-formulario">
                    <div className="col-12 col-md-6 text-md-right">
                        <label className='boldie'>{nombre1}</label>
                    </div>
                    <div className="mb-2 col-12 col-md-6 d-block" lang={navigator.language}>
                        <div className="col-md-10 p-0 d-flex">
                            {prefix && <span className="d-flex align-items-center mr-2">{prefix}</span>}
                            <Field type="text" name={campo1} className="form-control text-center text-md-left" placeholder={nombre1} />
                        </div>
                    </div>
                    {error1 ?
                        <div className="mb-2 col-12 d-block">
                            <div className="alert alert-warning m-0" role="alert">{nombre1}: {error1}</div>
                        </div>
                        : null}
                </div>
            ) : null}

            {campo2 ? (
                <div className="row linea-formulario">
                    <div className="col-12 col-md-6 text-md-right">
                        <label className='boldie'>{nombre2}</label>
                    </div>
                    <div className="mb-2 col-12 col-md-6 d-block" lang={navigator.language}>
                        <div className="col-md-10 p-0 d-flex">
                            {prefix && <span className="d-flex align-items-center mr-2">{prefix}</span>}
                            <Field type="text" name={campo2} className="form-control text-center text-md-left" placeholder={nombre2} />
                        </div>
                    </div>
                    {error2 ?
                        <div className="mb-2 col-12 d-block">
                            <div className="alert alert-warning m-0" role="alert">{nombre2}: {error2}</div>
                        </div>
                        : null}
                </div>
            ) : null}
        </>);
    }

    if ((!dato1 || !campo1) && (!dato2 || !campo2)) return null;

    return (<>
        {dato1 && campo1 ? (
            <div className="row linea-formulario">
                <div className="col-12 col-md-6 text-md-right">
                    <label className='boldie'>{nombre1}</label>
                </div>
                <div className="col-12 col-md-6 text-md-left">
                    <span>{dato1}</span>
                </div>
            </div>
        ) : null}

        {dato2 && campo2 ? (
            <div className="row linea-formulario">
                <div className="col-12 col-md-6 text-md-right">
                    <label className='boldie'>{nombre2}</label>
                </div>
                <div className="col-12 col-md-6 text-md-left">
                    <span>{dato2}</span>
                </div>
            </div>
        ) : null}
    </>);
};

const SelectRow = ({ edit, nombre, dataValue, defaultText, campo, validate, error,extraOnChange=null,
    options, optionKey, optionLabel, groupBy, placeholder, itemTemplate, showClearButton,tooltip_lbl, ...extraProps }) => {
    const [field, , { setTouched }] = useField({ name: campo, validate });
    // if we have a valid options array, but the selected option is not in it, we set it as null
    // for example, we just selected a new cultivo_especie, so we need to reset cultivo_variedad
    if (edit && Array.isArray(options) && field.value &&
    !options.some((option) => option[optionKey] === field.value)) {
        field.onChange({ target: { type: field.type, name: field.name, value: field.value = null } });
        setTouched(true);
    }
    const hasOptions = Array.isArray(options) && options.length;
    if (!campo) return null;
    const onFiltering = (e) => {
        if (e.text === "") {
            e.updateData(options);
        } else {
            const query = options.filter(
                (data) =>
                    field.name === "cultivo_especie_id" ? (data.Name.toLowerCase().search(e.text.toLowerCase()) !== -1)
                        : (data.texto.toLowerCase().search(e.text.toLowerCase()) !== -1)
            );
            e.updateData(query);
        }
    };
    let groupedOptions = null;
    if(groupBy && options?.[0]?.[groupBy]){
        groupedOptions = options?.reduce((acc, nodo) => {
            const existingItemIndex = acc.findIndex(item => item.label === nodo[groupBy]);
            if (existingItemIndex !== -1) {
                acc[existingItemIndex].items.push(nodo);
            } else {
                acc.push({ label: nodo[groupBy], items: [nodo] });
            }
            return acc;
        }, []);
    }
    if (edit && hasOptions) return (
        <div className="row linea-formulario">
            <div className="col-12 col-md-6 text-md-right">
                <label className='boldie'>{nombre}</label>

                {tooltip_lbl?
                    <TooltipPrime id={`dateTooltip${field.name}`} content={tooltip_lbl} position="right">
                        <FontAwesomeIcon style={{ color: "#1E84A6", marginLeft:"1rem" }} icon={faInfoCircle} />
                    </TooltipPrime>
                    :null}
            </div>
            <div className="mb-2 col-12 col-md-6 d-block">
                <div className={`text-left col-md-10 p-0 ${showClearButton && field.value ? "selectWithClear" : ""}`}>
                    <DropdownPrime
                        id='ddl-forms'
                        value={field.value}
                        onChange={({ value }) => {
                            field.onChange({ target: { type: field.type, name: field.name, value } });
                            setTouched(true);
                            if(extraOnChange){
                                extraOnChange(value);
                            }
                        }}
                        options={groupedOptions?groupedOptions:options}
                        optionLabel={optionLabel}
                        optionValue={optionKey}
                        placeholder={placeholder}
                        optionGroupLabel={groupedOptions && groupBy?"label":null}
                        optionGroupChildren={groupedOptions && groupBy?"items":null}
                        name={field.name}
                        showClear={showClearButton?true:false}
                        style={{width:"10vw"}}
                        itemTemplate={itemTemplate}
                        filter={options.length > 10}
                        {...extraProps}
                    />
                </div>
            </div>
            {error ?
                <div className="mb-2 col-12 d-block">
                    <div className="alert alert-warning m-0" role="alert">{nombre}: {error}</div>
                </div>
                : null}
        </div>
    );

    const dataToShow = edit ? field.value : dataValue;
    if (!dataToShow && !defaultText) return null;
    let selected = null;
    const thisOption = hasOptions && dataToShow && options.find((option) => option[optionKey] === dataToShow);
    if (thisOption) selected = thisOption[optionLabel] || "";

    return (
        <div className="row linea-formulario">
            <div className="col-12 col-md-6 text-md-right mtem-05">
                <label className='boldie'>{nombre}</label>
            </div>
            <div className="col-12 col-md-6 text-md-left mtem-05">
                <span>{selected || defaultText}</span>
            </div>
        </div>
    );
};

const RadioRow = ({ edit, dato, nombre, trueNombre, falseNombre, campo }) => {
    const [radio1Ref, setRadio1Ref] = useState(true);
    const [radio2Ref, setRadio2Ref] = useState(false);
    const [field, , { setTouched }] = useField({ name: campo });

    return (
        <div className="row linea-formulario">
            <div className="col-12 col-md-6 text-md-right mtem-05">
                <label className='boldie'>{nombre}</label>
            </div>
            <div className="col-12 col-md-6 text-md-left mtem-05">
                <div className='col'>
                    <RadioButton
                        inputId={trueNombre}
                        name={trueNombre}
                        value={trueNombre}
                        onChange={({ value }) => {
                            field.onChange({ target: { type: field.type, name: field.name, value } });
                            setRadio1Ref(false);
                            setTouched(true);
                            setRadio2Ref(false);
                        }}
                        checked={radio1Ref} style={{display:"inline-block", verticalAlign:"middle"}}
                    />
                    <label htmlFor={trueNombre} style={{display:"inline-block", verticalAlign:"middle", margin:0, marginLeft:"0.2rem", fontSize:"12px"}}>{trueNombre}</label>
                </div>
                <div className='col'>
                    <RadioButton
                        inputId={falseNombre}
                        name={falseNombre}
                        value={falseNombre}
                        onChange={({ value }) => {
                            field.onChange({ target: { type: field.type, name: field.name, value } });
                            setRadio2Ref(true);
                            setTouched(true);
                            setRadio1Ref(false);
                        }}
                        checked={radio2Ref} style={{display:"inline-block", verticalAlign:"middle"}}
                    />
                    <label htmlFor={falseNombre} style={{display:"inline-block", verticalAlign:"middle", margin:0, marginLeft:"0.2rem", fontSize:"12px"}}>{falseNombre}</label>
                </div>
            </div >
        </div >
    );
};

const capitalize = (s) => {
    const lower = s.toLowerCase();
    if (typeof s !== "string") return "";
    return lower.charAt(0).toUpperCase() + lower.slice(1);
};

const FormCropsSelector = ({ cultivos, sector, especieId, variedadId, edit, showSubEspecie = true, especiesCampo, variedadRequired, especieRequired, errors, showClearButton }) => {
    const { t } = useTranslation();

    const dataSource = useMemo(() => {
        if (!Array.isArray(cultivos) || !cultivos.length) return null;
        cultivos.sort(({ texto: a }, { texto: b }) => (a < b) ? -1 : 1);
        cultivos.forEach(cul => {
            Array.isArray(cul.especies) && cul.especies.length > 0 && cul.especies.sort(({ texto: a }, { texto: b }) => (a < b) ? -1 : 1);
        });

        const res = [];
        const newCrops = cultivos.filter(cul => cul.cultivo_tipo_id !== 3 && cul.cultivo_tipo_id !== 5 && cul.cultivo_tipo_id !== 2);
        newCrops.unshift(cultivos.filter(cul => cul.cultivo_tipo_id === 2)[0]); //nuts
        newCrops.unshift(cultivos.filter(cul => cul.cultivo_tipo_id === 5)[0]); //berries
        newCrops.unshift(cultivos.filter(cul => cul.cultivo_tipo_id === 3)[0]); //fruits

        if (newCrops) {
            for (const tipo of newCrops) {
                for (const especie of tipo.especies) {
                    // add a new category for farm species at top of selector, only if especiesCampo is passed
                    if (especiesCampo && especiesCampo.has(especie.cultivo_especie_id)) {
                        res.unshift({
                            Category: t("farms.fenologia.farmSpecies"),
                            Name: especie.texto,
                            ItemId: especie.cultivo_especie_id,
                            Childs: especie.variedades
                        });
                        continue;
                    }

                    const json = {};
                    json.Category = tipo.texto;
                    json.Name = especie.texto;
                    json.ItemId = especie.cultivo_especie_id;
                    json.Childs = especie.variedades;
                    res.push(json);
                }
            }
        }
        return res;
    }, [cultivos, especiesCampo, t]);

    const secondDataSelected = useMemo(() => {
        if (!Array.isArray(dataSource) || !dataSource.length) return null;
        const res = [];
        if (dataSource) {
            for (const d of dataSource) {
                if (d.Childs) {
                    for (const variedad of d.Childs) {
                        if (d.ItemId === especieId) {
                            res.push(variedad);
                        }
                    }
                }
            }
        }
        return res;
    }, [dataSource, especieId]);

    if (!Array.isArray(cultivos) || !cultivos.length) return null;
    const selected = dataSource.find((d) => d.ItemId === especieId);
    const secondSelected = secondDataSelected.length > 0 && secondDataSelected.find((sec) => sec.cultivo_variedad_id === variedadId);

    return (
        <>
            {especieId === null && sector.cultivos ?
                <div className="row linea-formulario">
                    <div className="col-12 col-md-6 text-md-right mtem-05">
                        <label className='boldie'>{t("cultivos.cultivos")}</label>
                    </div>
                    <div className="col-12 col-md-6 text-md-left mtem-05">
                        <span>{capitalize(`${sector.cultivos}`)}   </span>
                        <TooltipPrime id="form-cultivos"  content={t("cultivos.tooltipMsgOld")} >
                            <FontAwesomeIcon size="1x" icon={faInfoCircle} style={{ color: "lightblue" }} />
                        </TooltipPrime>
                    </div>
                </div>
                : null}

            {!edit ?
                <>
                    {selected &&
            <div className="row linea-formulario">
                <div className="col-12 col-md-6 text-md-right mtem-05">
                    <label className='boldie'>{t("cultivos.especie")}</label>
                </div>
                <div className="col-12 col-md-6 text-md-left mtem-05">
                    <span>{selected.Name}</span>
                </div>
            </div>}
                    {secondSelected &&
            <div className="row linea-formulario">
                <div className="col-12 col-md-6 text-md-right mtem-05">
                    <label className='boldie'>{t("cultivos.variedad")}</label>
                </div>
                <div className="col-12 col-md-6 text-md-left mtem-05">
                    <span>{secondSelected.texto}</span>
                </div>
            </div>}
                </>
                :
                <>
                    <SelectRow
                        edit={edit}
                        nombre={t("cultivos.especie")}
                        campo="cultivo_especie_id"
                        options={dataSource}
                        optionKey="ItemId"
                        optionLabel="Name"
                        groupBy="Category"
                        placeholder={t("cultivos.selectType")}
                        showClearButton={showClearButton}
                        validate={especieRequired && validateSomething}
                        error={especieRequired && errors?.cultivo_especie_id && t(errors?.cultivo_especie_id)}
                    />
                    {selected &&
            <SelectRow
                edit={edit}
                nombre={t("cultivos.variedad")}
                campo="cultivo_variedad_id"
                options={secondDataSelected}
                optionKey="cultivo_variedad_id"
                optionLabel="texto"
                placeholder={t("cultivos.selectVariety")}
                showClearButton={showClearButton}
                validate={variedadRequired && validateSomething}
                error={variedadRequired && errors?.cultivo_variedad_id && t(errors?.cultivo_variedad_id)}
            />}
                </>}
            {especieId && showSubEspecie ?
                <FormRow nombre1={t("cultivos.subespecie")} campo1="sub_especie" dato1={sector.sub_especie} edit={edit} />
                : null}
        </>
    );
};

const Vinculos = ({ accountDC, farmDC, zoneDC, gMapPosition, sensorDC }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    if (accountDC === null && farmDC === null && zoneDC === null && gMapPosition === null && sensorDC === null) { return null; }

    const dir = () => {
        const today = new Date();
        const twoWeeks = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 13);
        const fechas = [twoWeeks.toISOString().split("T")[0].replace(/-/g, "/"), today.toISOString().split("T")[0].replace(/-/g, "s/")];
        const sensorId = "2" + sensorDC.sensor_id;
        navigate({ pathname: "/farms/customchart", search: `?farm=${sensorDC.campo}&sensors=${JSON.stringify([parseInt(sensorId)])}&dates=${JSON.stringify(fechas)}` },
            {
                state: {
                    expanded: ["graficador"],
                    updateTree: true
                }
            });
    };

    return (
        <div className="row linea-formulario">
            <div className="col-12 col-md-6 text-md-right mtem-05">
                <label className='boldie'>{t("forms.vinculos")}</label>
            </div>
            <div className="col-12 col-md-6 text-md-left mtem-05">
                {(accountDC || farmDC) &&
          <>
              <TooltipPrime id="dirAccountDc"  content={t("forms.verEnDC")} position="bottom">
                  <a target="_blank" href={accountDC || farmDC} rel="noopener noreferrer">
                      <span className={accountDC ? "accountUrl" : "farmUrl"} />
                  </a>
              </TooltipPrime>

              {gMapPosition &&
            <TooltipPrime id="gmapDirFarm"  content={t("farms.verEnGmap")} position="bottom">
                <a target="_blank" href={gMapPosition} rel="noopener noreferrer">
                    <span className="farmGmapUrl" />
                </a>
            </TooltipPrime>}
          </>}
                {zoneDC &&
          <>
              {zoneDC.clima &&
            <TooltipPrime id="toolClima"  content={t("forms.sectorClimaVerEnDC")} position="bottom">
                <a target="_blank" href={zoneDC.clima} rel="noopener noreferrer">
                    <span className="gotaClima" />
                </a>
            </TooltipPrime>}
              {zoneDC.suelo &&
            <TooltipPrime id="toolSuelo"  content={t("forms.sectorSueloVerEnDC")} position="bottom">
                <a target="_blank" href={zoneDC.suelo} rel="noopener noreferrer">
                    <span className="gotaSuelo" />
                </a>
            </TooltipPrime>}
              {zoneDC.riego &&
            <TooltipPrime id="toolRiego"  content={t("forms.sectorRiegoVerEnDC")} position="bottom">
                <a target="_blank" href={zoneDC.riego} rel="noopener noreferrer">
                    <span className="gotaRiego" />
                </a>
            </TooltipPrime>}
          </>}

                {sensorDC &&
          <>
              {sensorDC.monitorear &&
            <TooltipPrime id="graficadorDir"  content={t("forms.verEnGraficador")} position="top">
                <a id='graficadorTool' target="_blank" className="pointer" onClick={() => dir()} rel="noopener noreferrer">
                    <span className='graficadorUrl' />
                </a>
            </TooltipPrime>}
          </>}

            </div>
        </div>
    );
};

const VinculoEquipo = ({ farmId, equipo_id, equipoRelacionado, t }) => {
    const navigate = useNavigate();

    if (equipoRelacionado === null || !equipo_id) return null;
    const dir = () => navigate({ pathname: "/farms/pumpsys", search: `?farm=${farmId}&pumpsys=${equipo_id}` },
        { state: { expanded: ["equipos", `equipo-${equipo_id}`], updateTree: true } });

    return (
        <>
            <div className="row linea-formulario">
                <div className="col-12 col-md-6 text-md-right mtem-05">
                    <label className='boldie'>{t("forms.equipo")}</label>
                </div>
                <div className="col-12 col-md-6 text-md-left mtem-05">
                    <a className='pointer' onClick={() => dir()} rel="noopener noreferrer">
                        {equipoRelacionado}
                    </a>
                </div>
            </div>
        </>
    );
};

const VinculoNodo = ({ farmId, nodo_id, nodoRelacionado, t }) => {
    const navigate = useNavigate();
    if (nodoRelacionado.nombre === null || !nodo_id) return null;
    const dir = () => navigate({ pathname: "/farms/node", search: `?farm=${farmId}&node=${nodo_id}` },
        {
            state: {
                expanded: nodoRelacionado.parent ? ["network",`nodo-${nodoRelacionado.parent}`,`nodo-${nodo_id}`] : ["network",`nodo-${nodo_id}`],
                updateTree: true
            }
        });

    return (
        <>
            {nodoRelacionado &&
        <div className="row linea-formulario">
            <div className="col-12 col-md-6 text-md-right mtem-05">
                <label className='boldie'>{t("forms.nodo")}</label>
            </div>
            <div className="col-12 col-md-6 text-md-left mtem-05">
                <a className="pointer" onClick={() => dir()} rel="noopener noreferrer">
                    {nodoRelacionado.nombre}
                </a>
            </div>
        </div>}
        </>
    );
};

const VinculoHidra = ({ farmId, hidraRelacionada, t }) => {
    const navigate = useNavigate();

    if (hidraRelacionada.hidra === null || hidraRelacionada.parent === null) return null;
    const dir = () => navigate({ pathname: "/farms/hydraulic", search: `?farm=${farmId}&hydraulic=${hidraRelacionada.hidra.hidraulica_id}` },
        {
            state: {
                expanded: ["equipos",`equipo-${hidraRelacionada.parent}`,`hidraulicas-${hidraRelacionada.parent}`,`hidraulica-${hidraRelacionada.hidra.hidraulica_id}`],
                updateTree: true
            }
        });

    return (
        <>
            <div className="row linea-formulario">
                <div className="col-12 col-md-6 text-md-right mtem-05">
                    <label className='boldie'>{t("forms.hidraulica")}</label>
                </div>
                <div className="col-12 col-md-6 text-md-left mtem-05">
                    <a className="pointer" onClick={() => dir()} rel="noopener noreferrer">
                        {hidraRelacionada.hidra.nombre}
                    </a>
                </div>
            </div>
        </>
    );
};
const VinculoAguasArribaAguasAbajo = ({ farmId, hidraulicas, t, aguasArriba }) => {
    const navigate = useNavigate();

    if (hidraulicas === null || hidraulicas === undefined || hidraulicas.length < 1) return null;

    return (
        <>
            <div className="row linea-formulario">
                <div className="col-12 col-md-6 text-md-right mtem-05">
                    <label className='boldie'>{aguasArriba ? t("forms.upstream") : t("forms.downstream")}</label>
                </div>
                <div className="col-12 col-md-6 text-md-left mtem-05">
                    {hidraulicas.length > 0 ?
                        <div>
                            {hidraulicas.map((h, _index) =>
                                <div key={_index}>
                                    <a id={h.hidraulica_id} key={_index} className="pointer" onClick={() => navigate({ pathname: "/farms/hydraulic", search: `?farm=${farmId}&hydraulic=${h.hidraulica_id}` },
                                        { state: { expanded: ["sectores",`sector-${h.parentId}`,`hidraulicas-${h.parentId}`,`hidraulica-${h.hidraulica_id}_sector-${h.parentId}`], updateTree: true } })} rel="noopener noreferrer"
                                    >
                                        {h.nombre}
                                    </a>
                                    <div>
                                        {hidraulicas.length - 1 !== _index && " "}
                                    </div>
                                </div>
                            )}
                        </div>
                        :
                        "-"}
                </div>
            </div>
        </>
    );
};

const minDate = new Date("1/1/2020 00:00:00 AM");
const maxDate = new Date("1/1/2020 11:59:59 PM");
const TimeRow = ({ edit, nombre, dato, defaultText, campo, validate, placeholder }) => {
    const { t, i18n } = useTranslation();
    const [field] = useField({ name: campo, validate });
    if (!campo) return null;

    if (edit) return (
        <div className="row linea-formulario">
            <div className="col-12 col-md-6 text-md-right">
                <label className='boldie'>{nombre}</label>
            </div>
            <div className="mb-2 col-12 col-md-6 d-block">
                <div className="col-md-10 p-0">
                    <CalendarPrime
                        t={t}
                        minDate={minDate}
                        maxDate={maxDate}
                        name={field.name}
                        placeholder={placeholder}
                        value={field.value}
                        onChange={({ value }) => field.onChange({ target: { type: field.type, name: field.name, value } })}
                        locale={i18n.language}
                        timeOnly
                    />
                </div>
            </div>
        </div>
    );

    const dataToShow = edit ? field.value : dato;
    if (!dataToShow && !defaultText) return null;

    return (
        <div className="row linea-formulario">
            <div className="col-12 col-md-6 text-md-right mtem-05">
                <label className='boldie'>{nombre}</label>
            </div>
            <div className="col-12 col-md-6 text-md-left mtem-05">
                <span>{dataToShow || defaultText}</span>
            </div>
        </div>
    );
};


const DateRow = ({ edit, nombre, dato, defaultText, campo, error, validate,minDate=null,maxDate=null, placeholder, onChange=null,tooltip=null }) => {
    const { t, i18n } = useTranslation();
    const [field] = useField({ name: campo, validate });
    if (!campo) return null;

    if (edit) return (
        <div className="row linea-formulario">
            <div className="col-12 col-md-6 text-md-right">
                <label className='boldie'>{nombre}</label>
                {tooltip?
                    <TooltipPrime id={`dateTooltip${field.name}`} content={tooltip} position="right">
                        <FontAwesomeIcon style={{ color: "#1E84A6", marginLeft:"1rem" }} icon={faInfoCircle} />
                    </TooltipPrime>
                    :null}
            </div>
            <div className="mb-2 col-12 col-md-6 d-block">
                <div className="col-md-10 p-0">
                    <CalendarPrime
                        t={t}
                        minDate={minDate}
                        maxDate={maxDate}
                        name={field.name}
                        placeholder={placeholder}
                        value={field.value}
                        onChange={({ value }) => {
                            if(onChange){
                                onChange(value);
                            }else{
                                field.onChange({ target: { type: field.type, name: field.name, value } });
                            }
                        }}
                        locale={i18n.language}
                        style={{width:"10vw"}}
                    />
                </div>
            </div>
            {error ?
                <div className="mb-2 col-12 d-block">
                    <div className="alert alert-warning m-0" role="alert">{nombre}: {error}</div>
                </div>
                : null}
        </div>
    );

    const dataToShow = edit ? field.value : dato;
    if (!dataToShow && !defaultText) return null;

    return (
        <div className="row linea-formulario">
            <div className="col-12 col-md-6 text-md-right mtem-05">
                <label className='boldie'>{nombre}</label>
            </div>
            <div className="col-12 col-md-6 text-md-left mtem-05">
                <span>{dataToShow || defaultText}</span>
            </div>
        </div>
    );
};
const CheckBoxRow = ({ edit, nombre, dato, defaultText, campo, validate, placeholder, onChange=null }) => {
    const { t, i18n } = useTranslation();
    const [field] = useField({ name: campo, validate });
    if (!campo) return null;

    if (edit) return (
        <div className="row linea-formulario">
            <div className="col-12 col-md-6 text-md-right">
                <label className='boldie'>{nombre}</label>
            </div>
            <div className="mb-2 col-12 col-md-6 d-block">
                <div className="col-md-10 p-0">
                    <Checkbox
                        checked={field.value}
                        onChange={({ checked }) => onChange?onChange(checked):field.onChange({ target: { type: field.type, name: field.name, value:checked } })}
                        style={{width:"10vw"}}
                    />
                </div>
            </div>
        </div>
    );

    const dataToShow = edit ? field.value : dato;
    if (!dataToShow && !defaultText) return null;

    return (
        <div className="row linea-formulario">
            <div className="col-12 col-md-6 text-md-right mtem-05">
                <label className='boldie'>{nombre}</label>
            </div>
            <div className="col-12 col-md-6 text-md-left mtem-05">
                <span>{dataToShow || defaultText}</span>
            </div>
        </div>
    );
};

const TituloParametros = ({ titulo }) => {

    return (
        <div>
            <span className="titulo-parametro align-middle">{titulo}</span>
            <hr className="titulo-parametro-hr" />
        </div>
    );
};

const EmailChips = ({ nombre, edit, error, setError, campoSolo, isAutomaticReports }) => {
    const { t } = useTranslation();
    const [{ value: emails_str }, , { setValue: setEmailsStr }] = useField({ name: "mails_envio_reporte" });
    const emails = useMemo(() => emails_str && emails_str.split(",") || emails_str, [emails_str]);
    const [emailValue, setEmailValue] = useState(null);

    return (
        <div className="row linea-formulario">
            <div className="col-12 col-md-6 text-md-right mtem-05">
                <label className='boldie'>{nombre}</label>
            </div>

            <div className="col-12 col-md-6 text-md-left mtem-05">
                {edit ?
                    <>
                        <div id="chips">
                            <div className="d-flex">
                                <InputTextPrime
                                    id="inputAddEmail"
                                    value={emailValue}
                                    placeholder={campoSolo}
                                    onChange={e => setEmailValue(e.target.value)}
                                    style={{width:"50%"}}
                                />

                                <ButtonPrime t={t} template="formPlus"
                                    onClick={() => {
                                        const error = validateEmail50(emailValue);
                                        setError(error);
                                        if (!error && emailValue) {
                                            if (!emails || !emails.find(e => e.toLowerCase() == emailValue.toLowerCase())) {
                                                if (isAutomaticReports && (emails?.length + 1) > 2){
                                                    return;
                                                }
                                                emails && emails.push(emailValue);
                                                setEmailsStr(emails && emails.join(",") || emailValue);
                                            } else {
                                                setError(t("forms.correoRegistrado"));
                                            }
                                        }
                                    }}
                                />
                            </div>
                        </div>
                        {emails &&  emails.map(email =>
                            <ChipPrime
                                key={email}
                                label={email}
                                removeFunction={(e) => {
                                    const index = emails.findIndex(e => e===email);
                                    emails.splice(index, 1);
                                    setEmailsStr(emails.join(","));
                                }}
                                removable
                            />)}
                    </>
                    :
                    <div className="col-12 col-md-6 text-md-left p-0">
                        {/* {setError(null)} */}
                        {
                            Array.isArray(emails) ? emails.map((e =>
                                <span key={e}>{e}<br /></span>
                            ))
                                :
                                <span>-</span>
                        }

                    </div>}
            </div>
            {
                error ?
                    <div className="mt-2 mb-2 col-12 d-block">
                        <div className="alert alert-warning m-0" role="alert">{campoSolo}: {error}</div>
                    </div>
                    : null
            }
        </div >
    );
};

const CustomError = ({ i18nLabel, errorValue }) => {
    const { t } = useTranslation();
    return (
        <div className="mt-2 mb-2 col-12 d-block">
            <div className="alert alert-warning m-0" role="alert">
                {t(i18nLabel)}: {t(errorValue)}
            </div>
        </div>
    );
};

const MultiSelectRow = ({ edit, nombre, campo, dataValue, validate, error,showSelectAll=true,
    options, optionKey, optionLabel, groupBy, placeholder, fRef,extraOnChange=null,tooltip=null, ...extraProps }) => {
    const [field, , { setTouched }] = useField({ name: campo, validate });
    const { t, i18n } = useTranslation();
    const hasOptions = Array.isArray(options) && options.length;
    let groupedOptions = null;
    if(groupBy && options?.[0]?.[groupBy]){
        groupedOptions = options?.reduce((acc, nodo) => {
            const existingItemIndex = acc.findIndex(item => item.label === nodo[groupBy]);
            if (existingItemIndex !== -1) {
                acc[existingItemIndex].items.push(nodo);
            } else {
                acc.push({ label: nodo[groupBy], items: [nodo] });
            }
            return acc;
        }, []);
    }
    if (!campo) return null;
    if (edit && hasOptions) return (
        <div className="row linea-formulario">
            <div className="col-12 col-md-6 text-md-right">
                <label className='boldie'>{nombre}</label>
                {tooltip?
                    <TooltipPrime id={`dateTooltip${field.name}`} content={tooltip} position="right">
                        <FontAwesomeIcon style={{ color: "#1E84A6", marginLeft:"1rem" }} icon={faInfoCircle} />
                    </TooltipPrime>
                    :null}
            </div>
            <div className="mb-2 col-12 col-md-6 d-block">
                <div className="col-md-10 p-0">
                    <MultiSelectPrime
                        value={dataValue ?? field.value}
                        onBlur={() => setTouched(true)}
                        onChange={({ value }) => {
                            field.onChange({ target: { type: field.type, name: field.name, value } });
                            setTouched(true);
                            if(extraOnChange){
                                extraOnChange(value);
                            }
                        }}
                        style={{height:`${dataValue?.length &&extraProps?.display==="chip"?dataValue.length*38:38}px`}}
                        options={groupedOptions?groupedOptions:options}
                        optionLabel={optionLabel}
                        optionValue={optionKey}
                        optionGroupLabel={groupedOptions?"label":null}
                        optionGroupChildren={groupedOptions?"items":null}
                        placeholder={placeholder}
                        selectAllLabel={t("forms.selectAll")}
                        selectedItemsLabel={t("forms.itemsSelected",{ total:dataValue?.length })}
                        showSelectAll={showSelectAll}
                        maxSelectedLabels={0}
                        {...extraProps}
                    />
                </div>
            </div>
            {error ?
                <div className="mb-2 col-12 d-block">
                    <div className="alert alert-warning m-0" role="alert">{nombre}: {error}</div>
                </div>
                : null}
        </div>
    );
    return null;
};

export {
    callSend, countriesList, HeaderButtons, LocationSubsection, AreaRow, FormRow, RawRow, PhoneRow, TimeRow, SelectRow,
    FormCropsSelector, capitalize, Vinculos, VinculoEquipo, VinculoNodo, VinculoHidra, VinculoAguasArribaAguasAbajo,
    TituloParametros, EmailChips, RadioRow, CustomError, MultiSelectRow, DateRow, CheckBoxRow
};
